<template>
  <div id="app">
<router-view></router-view>
  </div>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  components: {
    HelloWorld
  },
  mounted(){
console.log(process.env.NODE_ENV);
  }
}
</script>

<style>
body,
html {
  min-width: 1000px !important;
  background: #f2f2f2;
  color: #333;
  width: 100%;
  overflow: auto;
  margin: 0px;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;

}
</style>
