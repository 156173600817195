import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)
// import Home from '../views/Home.vue'

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const VueRouterReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace(to) {
  return VueRouterReplace.call(this, to).catch(err => err)
}
const routes  =[{
    path:'/',
    redirect:'/Home'
},{
    path: '/Home',
    name: 'Home',
    component: () =>
      import( /* webpackChunkName: "about" */ '../views/Home.vue'),
    meta: {} 
},{
  path: '/gaodingEdit',
  name: 'gaodingEdit',
  component: () =>
    import( /* webpackChunkName: "about" */ '../views/gaodingEdit.vue'),
  meta: {} 
}];
const router = new VueRouter({
    mode: 'history',
    base: '/sczx',
    routes
  })
  
  
  router.beforeEach((to, from, next) => {
    if (window._hmt) {
      if (to.path) {
        window._hmt.push(['_trackPageview', '/#' + to.fullPath])
      }
    }
    next()
  })
  
  export default router